import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://api.esportivabrasil.com',
    timeout: 1800000
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('x-token');
    config.headers['X-Web-Token'] = true;
    config.headers['X-Access-Token'] = token ? token : undefined;
    return config;
}, (error) => {
	return Promise.reject(error);
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	return Promise.reject(error.response);
});